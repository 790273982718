// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { I18nextProvider } from 'react-i18next';
import RootLayout from './RootLayout';

import i18n from '../../locales/i18n';

// Layout components

import MenuHeader from './components/MenuHeader';
import WemapCookiesConsent from './components/WemapCookiesConsent';

const Container = styled.div`
    width: 100%;
    position: absolute;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const Menu = styled(MenuHeader)`
    flex-grow: 0;
`;

class LayoutTemplate extends RootLayout {
    render() {
        let { children } = this.props;
        return (
            <I18nextProvider i18n={i18n}>
                <Container>
                    <Menu className='desktop' />
                    {children}
                    <WemapCookiesConsent />
                </Container>
            </I18nextProvider>
        );
    }
}

LayoutTemplate.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ])
};


export default LayoutTemplate;
