import React from 'react';
import Helmet from 'react-helmet';
// Layout component
import LayoutTemplateMapview from '../components/layouts/LayoutTemplateMapview';

// Base components
import LivemapView from '../components/LivemapView';

const MapViewPage = () => {

    return (
        <React.Fragment>
            <Helmet defer={false}>
                <title>Mapview | Wemap</title>
                <meta name="description"
                    content="Mapview" />
            </Helmet>
            <LayoutTemplateMapview>
                <LivemapView />
            </LayoutTemplateMapview>
        </React.Fragment>
    );
};

export default MapViewPage;
