import React from 'react';
import styled from 'styled-components';

// Base components
import LocalizedLink from '../../LocalizedLink';

const MenuHeaderUl = styled.ul`
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin:0;
    padding:0;
    background-color:#227EE6;
    height:70px;
`;

const MenuHeaderLogo = styled(LocalizedLink)`
    flex-grow: 0;
    flex-shrink: 1;
    margin-left:15px;
`;

const MenuHeaderImg = styled.img`
    width:25%;
`;

const MenuHeader = () => {
    return (
        <MenuHeaderUl>
            <MenuHeaderLogo to='home'
                aria-label="Link to home">
                <MenuHeaderImg src='/images/logo-wemap-mapview.svg'
                    alt="Wemap logo" />
            </MenuHeaderLogo>

        </MenuHeaderUl>
    );
};

export default React.memo(MenuHeader);
