import React from 'react';

const style = {
    margin: 0,
    padding: 0,
    width: '100%',
    height: '100%',
    overflow: 'hidden'
};

export default class LivemapView extends React.Component {
    componentDidMount() {
        this.waitLivemapLoad();
    }

    createLivemap = () => {
        const wemap = window.wemap;
        const container = document.getElementById('map_container');
        // eslint-disable-next-line
        const params = window.location.search.split(/[\?&]/).reduce((acc, val) => {
            if (!val.length) {
                return acc;
            }

            const values = val.split('=');

            if (!values.length === 2) {
                return acc;
            }
            acc[values[0]] = values[1];

            return acc;
        }, {});

        const options = Object.assign({
            dist: 'ufe',
            deeplinkingenabled: false
        }, params);

        if (options.emmid) {
            delete options.dist;
        }

        if (!options.hasOwnProperty(options.clustericon)) {
            options.clustericon = 'https://api.getwemap.com/images/pps-categories/users/0d6d04fd7e4bda08ceb2d497.23690358.png';
        }

        // Create a Livemap
        wemap.v1.createLivemap(container, options);
    };

    waitLivemapLoad = () => {
        if (!window.wemap) {
            setTimeout(this.waitLivemapLoad, 200);
        } else {
            this.createLivemap();
        }
    };

    render = () => {

        return (
            <div id="map_container"
                style={style}>
            </div>
        );
    };
}
